module.exports = [{
      plugin: require('../../../node_modules/.pnpm/@flogy+gatsby-theme-fgs-legal@5.0.0_@flogy+gatsby-theme-fgs-layout@5.0.0_gatsby@5.13.7_babel-_gyi2kn3jyeclu5sb2zquzo7vhu/node_modules/@flogy/gatsby-theme-fgs-legal/gatsby-browser.tsx'),
      options: {"plugins":[],"currentWebsiteUrl":"https://schweizerdeutsch-uebersetzen.ch","companyNominative":"Florian Gyger Software","companyDative":"der Firma Florian Gyger Software","companyAccusative":"die Firma Florian Gyger Software","companyLocation":"Lenzburg","impressum":{"path":"/impressum","addresses":[{"title":"Verantwortlich für den Inhalt","company":"Florian Gyger Software","street":"Gustav Zeiler-Ring 3","location":"5600 Lenzburg","email":"info@floriangyger.ch","website":"https://floriangyger.ch/"}]},"dataPrivacy":{"path":"/datenschutz","usedFeatures":[],"lastChangedDate":"","responsiblePerson":{"nameAccusative":"","email":""}},"cookieBanner":{"enable":true}},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@8.57.1__encoding@0.1.1_h76do4omkf5orf6ajjeajdq3ce/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Schweizerdeutsch Übersetzer App","short_name":"Schweizerdeutsch","start_url":"/","background_color":"#9CB58B","theme_color":"#9CB58B","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a707f741f4b9f713270c02f5568d1d7e"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-google-tagmanager@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@8.57.1__encod_u2wuzp4z7scqfclzvpgrfeqnhq/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-55LV598F","includeInDevelopment":false,"enableWebVitalsTracking":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_eslint@8.57.1__encoding@0.1.13_react-dom@18.2.0_react@18.2._576pmxso4g3ahlkbgzvqzbqdxa/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
