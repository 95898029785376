exports.components = {
  "component---node-modules-pnpm-flogy-gatsby-theme-fgs-contact-6-0-0-flogy-gatsby-theme-fgs-layout-5-0-0-gatsby-5-13-7-babe-gt-3-blokwla-3-oq-5-zbefcqwl-6-goa-node-modules-flogy-gatsby-theme-fgs-contact-src-pages-contact-tsx": () => import("./../../../../../node_modules/.pnpm/@flogy+gatsby-theme-fgs-contact@6.0.0_@flogy+gatsby-theme-fgs-layout@5.0.0_gatsby@5.13.7_babe_gt3blokwla3oq5zbefcqwl6goa/node_modules/@flogy/gatsby-theme-fgs-contact/src/pages/contact.tsx" /* webpackChunkName: "component---node-modules-pnpm-flogy-gatsby-theme-fgs-contact-6-0-0-flogy-gatsby-theme-fgs-layout-5-0-0-gatsby-5-13-7-babe-gt-3-blokwla-3-oq-5-zbefcqwl-6-goa-node-modules-flogy-gatsby-theme-fgs-contact-src-pages-contact-tsx" */),
  "component---node-modules-pnpm-flogy-gatsby-theme-fgs-legal-5-0-0-flogy-gatsby-theme-fgs-layout-5-0-0-gatsby-5-13-7-babel-gyi-2-kn-3-jyeclu-5-sb-2-zquzo-7-vhu-node-modules-flogy-gatsby-theme-fgs-legal-src-pages-impressum-tsx": () => import("./../../../../../node_modules/.pnpm/@flogy+gatsby-theme-fgs-legal@5.0.0_@flogy+gatsby-theme-fgs-layout@5.0.0_gatsby@5.13.7_babel-_gyi2kn3jyeclu5sb2zquzo7vhu/node_modules/@flogy/gatsby-theme-fgs-legal/src/pages/impressum.tsx" /* webpackChunkName: "component---node-modules-pnpm-flogy-gatsby-theme-fgs-legal-5-0-0-flogy-gatsby-theme-fgs-layout-5-0-0-gatsby-5-13-7-babel-gyi-2-kn-3-jyeclu-5-sb-2-zquzo-7-vhu-node-modules-flogy-gatsby-theme-fgs-legal-src-pages-impressum-tsx" */),
  "component---src-flogy-gatsby-theme-fgs-legal-pages-data-privacy-tsx": () => import("./../../../src/@flogy/gatsby-theme-fgs-legal/pages/data-privacy.tsx" /* webpackChunkName: "component---src-flogy-gatsby-theme-fgs-legal-pages-data-privacy-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-angebote-fuer-unternehmen-index-tsx": () => import("./../../../src/pages/angebote-fuer-unternehmen/index.tsx" /* webpackChunkName: "component---src-pages-angebote-fuer-unternehmen-index-tsx" */),
  "component---src-pages-angebote-fuer-unternehmen-individuelle-formatvorlagen-tsx": () => import("./../../../src/pages/angebote-fuer-unternehmen/individuelle-formatvorlagen.tsx" /* webpackChunkName: "component---src-pages-angebote-fuer-unternehmen-individuelle-formatvorlagen-tsx" */),
  "component---src-pages-beispiele-tsx": () => import("./../../../src/pages/beispiele.tsx" /* webpackChunkName: "component---src-pages-beispiele-tsx" */),
  "component---src-pages-datenschutz-mobileapps-index-tsx": () => import("./../../../src/pages/datenschutz/mobileapps/index.tsx" /* webpackChunkName: "component---src-pages-datenschutz-mobileapps-index-tsx" */),
  "component---src-pages-datenschutz-webapp-index-tsx": () => import("./../../../src/pages/datenschutz/webapp/index.tsx" /* webpackChunkName: "component---src-pages-datenschutz-webapp-index-tsx" */),
  "component---src-pages-datenschutz-website-index-tsx": () => import("./../../../src/pages/datenschutz/website/index.tsx" /* webpackChunkName: "component---src-pages-datenschutz-website-index-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-interview-bewerbung-fragen-vorstellungsgespraech-fuehrungskraft-tsx": () => import("./../../../src/pages/interview/bewerbung/fragen-vorstellungsgespraech-fuehrungskraft.tsx" /* webpackChunkName: "component---src-pages-interview-bewerbung-fragen-vorstellungsgespraech-fuehrungskraft-tsx" */),
  "component---src-pages-interview-bewerbung-fragen-vorstellungsgespraech-kaufleute-tsx": () => import("./../../../src/pages/interview/bewerbung/fragen-vorstellungsgespraech-kaufleute.tsx" /* webpackChunkName: "component---src-pages-interview-bewerbung-fragen-vorstellungsgespraech-kaufleute-tsx" */),
  "component---src-pages-interview-bewerbung-fragen-vorstellungsgespraech-lehrperson-tsx": () => import("./../../../src/pages/interview/bewerbung/fragen-vorstellungsgespraech-lehrperson.tsx" /* webpackChunkName: "component---src-pages-interview-bewerbung-fragen-vorstellungsgespraech-lehrperson-tsx" */),
  "component---src-pages-interview-bewerbung-fragen-vorstellungsgespraech-lehrstelle-tsx": () => import("./../../../src/pages/interview/bewerbung/fragen-vorstellungsgespraech-lehrstelle.tsx" /* webpackChunkName: "component---src-pages-interview-bewerbung-fragen-vorstellungsgespraech-lehrstelle-tsx" */),
  "component---src-pages-interview-bewerbung-fragen-vorstellungsgespraech-softwareentwicklung-tsx": () => import("./../../../src/pages/interview/bewerbung/fragen-vorstellungsgespraech-softwareentwicklung.tsx" /* webpackChunkName: "component---src-pages-interview-bewerbung-fragen-vorstellungsgespraech-softwareentwicklung-tsx" */),
  "component---src-pages-interview-fragen-fuer-dein-interview-tsx": () => import("./../../../src/pages/interview/fragen-fuer-dein-interview.tsx" /* webpackChunkName: "component---src-pages-interview-fragen-fuer-dein-interview-tsx" */),
  "component---src-pages-interview-interview-nachbearbeiten-studium-tsx": () => import("./../../../src/pages/interview/interview-nachbearbeiten-studium.tsx" /* webpackChunkName: "component---src-pages-interview-interview-nachbearbeiten-studium-tsx" */),
  "component---src-pages-interview-interviewfragen-automatisch-erkennen-tsx": () => import("./../../../src/pages/interview/interviewfragen-automatisch-erkennen.tsx" /* webpackChunkName: "component---src-pages-interview-interviewfragen-automatisch-erkennen-tsx" */),
  "component---src-pages-interview-ki-nutzen-fuer-interviews-tsx": () => import("./../../../src/pages/interview/ki-nutzen-fuer-interviews.tsx" /* webpackChunkName: "component---src-pages-interview-ki-nutzen-fuer-interviews-tsx" */),
  "component---src-pages-interview-leitfaden-fuer-erfolgreiches-interview-tsx": () => import("./../../../src/pages/interview/leitfaden-fuer-erfolgreiches-interview.tsx" /* webpackChunkName: "component---src-pages-interview-leitfaden-fuer-erfolgreiches-interview-tsx" */),
  "component---src-pages-interview-tools-fuer-interviews-tsx": () => import("./../../../src/pages/interview/tools-fuer-interviews.tsx" /* webpackChunkName: "component---src-pages-interview-tools-fuer-interviews-tsx" */),
  "component---src-pages-landing-diktierfunktion-fuer-mundart-dialekte-handy-tsx": () => import("./../../../src/pages/landing/diktierfunktion-fuer-mundart-dialekte-handy.tsx" /* webpackChunkName: "component---src-pages-landing-diktierfunktion-fuer-mundart-dialekte-handy-tsx" */),
  "component---src-pages-landing-gottesdienst-transkribieren-mundart-tsx": () => import("./../../../src/pages/landing/gottesdienst-transkribieren-mundart.tsx" /* webpackChunkName: "component---src-pages-landing-gottesdienst-transkribieren-mundart-tsx" */),
  "component---src-pages-landing-interview-transkribieren-software-schweizerdeutsch-tsx": () => import("./../../../src/pages/landing/interview-transkribieren-software-schweizerdeutsch.tsx" /* webpackChunkName: "component---src-pages-landing-interview-transkribieren-software-schweizerdeutsch-tsx" */),
  "component---src-pages-landing-mundart-sprachnachricht-in-text-uebersetzen-tsx": () => import("./../../../src/pages/landing/mundart-sprachnachricht-in-text-uebersetzen.tsx" /* webpackChunkName: "component---src-pages-landing-mundart-sprachnachricht-in-text-uebersetzen-tsx" */),
  "component---src-pages-landing-mundart-video-untertitel-erstellen-tsx": () => import("./../../../src/pages/landing/mundart-video-untertitel-erstellen.tsx" /* webpackChunkName: "component---src-pages-landing-mundart-video-untertitel-erstellen-tsx" */),
  "component---src-pages-landing-rechtschreibung-dank-diktierfunktion-tsx": () => import("./../../../src/pages/landing/rechtschreibung-dank-diktierfunktion.tsx" /* webpackChunkName: "component---src-pages-landing-rechtschreibung-dank-diktierfunktion-tsx" */),
  "component---src-pages-landing-schweizerdeutsch-transkribieren-app-tsx": () => import("./../../../src/pages/landing/schweizerdeutsch-transkribieren-app.tsx" /* webpackChunkName: "component---src-pages-landing-schweizerdeutsch-transkribieren-app-tsx" */),
  "component---src-pages-landing-schweizerdeutsch-verstehen-lernen-tsx": () => import("./../../../src/pages/landing/schweizerdeutsch-verstehen-lernen.tsx" /* webpackChunkName: "component---src-pages-landing-schweizerdeutsch-verstehen-lernen-tsx" */),
  "component---src-pages-landing-sitzungsprotokoll-automatisch-erstellen-tsx": () => import("./../../../src/pages/landing/sitzungsprotokoll-automatisch-erstellen.tsx" /* webpackChunkName: "component---src-pages-landing-sitzungsprotokoll-automatisch-erstellen-tsx" */),
  "component---src-pages-lernen-schweizerdeutsch-lernen-als-deutschsprachige-person-tsx": () => import("./../../../src/pages/lernen/schweizerdeutsch-lernen-als-deutschsprachige-person.tsx" /* webpackChunkName: "component---src-pages-lernen-schweizerdeutsch-lernen-als-deutschsprachige-person-tsx" */),
  "component---src-pages-offerte-tsx": () => import("./../../../src/pages/offerte.tsx" /* webpackChunkName: "component---src-pages-offerte-tsx" */),
  "component---src-pages-preise-tsx": () => import("./../../../src/pages/preise.tsx" /* webpackChunkName: "component---src-pages-preise-tsx" */),
  "component---src-pages-produktivitaet-schweizer-ki-apps-tsx": () => import("./../../../src/pages/produktivitaet/schweizer-ki-apps.tsx" /* webpackChunkName: "component---src-pages-produktivitaet-schweizer-ki-apps-tsx" */),
  "component---src-pages-video-untertitel-srt-untertitel-uebersetzen-tsx": () => import("./../../../src/pages/video-untertitel/srt-untertitel-uebersetzen.tsx" /* webpackChunkName: "component---src-pages-video-untertitel-srt-untertitel-uebersetzen-tsx" */),
  "component---src-pages-video-untertitel-srt-untertitel-verschieben-tsx": () => import("./../../../src/pages/video-untertitel/srt-untertitel-verschieben.tsx" /* webpackChunkName: "component---src-pages-video-untertitel-srt-untertitel-verschieben-tsx" */),
  "component---src-pages-videoanleitungen-tsx": () => import("./../../../src/pages/videoanleitungen.tsx" /* webpackChunkName: "component---src-pages-videoanleitungen-tsx" */)
}

